import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import imgStyles from "assets/style/image.js"
import { Link } from "gatsby";

const UseStyles = makeStyles(styles);
const UseImgStyles = makeStyles(imgStyles);

const miod = {
  color: '#ca9502' 
}

export default function dzienJednosci2019() {
  const classes = UseStyles();
  const imgClass = UseImgStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{ display: 'flex' }}>
          <div style={{ color: 'black', padding: '5px ', margin: '0 auto' }} >
            <Link to="/szkola-animatora-5-2019-2020">
                <img src={'/animator-5/szkola_animatora_5.jpg'} alt={'plakat szkoła animatora 5 2020'} className={imgClass.img} />
            </Link>
            <Link to="/szkola-animatora-4-29-02-2020">
                <img src={'/generalSlides/szkola_animatora_4.jpg'} alt={'plakat szkoła animatora 4 2019'} className={imgClass.img} />
            </Link>
            <Link to="/szkola-animatora-3-2019">
                <img src={'/generalSlides/szkola-animatora-3-2019-2020.jpg'} alt={'plakat szkoła animatora 3 2019'} className={imgClass.img} />
            </Link>
            <Link to="/szkola-animatora-2-2019">
                <img src={'/generalSlides/szkola-animatora-2-2019.jpg'} alt={'plakat szkoła animatora 2 2019'} className={imgClass.img} />
            </Link>
            <Link to="/szkola-animatora-1-2019">
                <img src={'/generalSlides/szkola-animatora-1-2019.jpg'} alt={'plakat szkoła animatora 1 2019'} className={imgClass.img} />
            </Link>
            <div>
              <h2 className="miod"><strong style={miod}>DIECEZJALNA SZKOŁA ANIMATORA</strong> </h2>
              <p><em>„Jeden drugiego brzemiona noście i tak wypełnicie prawo Chrystusowe” Ga 6, 2</em> </p>
              <p className="miod"><strong style={miod}>Wprowadzenie</strong></p>
              <p>Diecezjalna Szkoła Animatora (DSA) powstaje w środowisku Katolickiej Odnowy w Duchu Świętym diecezji opolskiej, a więc zasadniczo przeznaczona jest dla formacji animatorów grup i wspólnot Odnowy zrzeszonych w koordynacji opolskiej. Z uwagi jednak na uniwersalny zakres tematyczny szkoły, jest ona otwarta także na formację do posługi w innych wspólnotach szeroko rozumianego nurtu charyzmatycznego. </p>
              <p>Do szkoły może się zgłosić każdy chętny kandydat na animatora lub animator będący już w posłudze, który rozezna u siebie taką potrzebę. <span style={{ color: 'red' }}>Uwaga!!! Osoby zainteresowane uczestnictwem w szkole zapisują się na pierwszym spotkaniu.</span></p>
              <p>Od zgłoszonych osób wymagany jest następujący poziom przygotowania ogólnego:</p>
              <ul >
                <li className="miod-point">Podstawowa formacja chrześcijańska, a więc znajomość prawd wiary oraz doświadczenie życia tą wiarą w relacji z Jezusem Chrystusem w Jego Kościele.</li>
                <li className="miod-point">Otwartość na innych ludzi, która – poprzez postawę pełnego szacunku słuchania innych oraz szczerego dzielenia się z nimi swoim własnym doświadczeniem – daje szansę na wzajemne ubogacanie się i rozwój. </li>
                <li className="miod-point">Wewnętrzne przekonanie o tym, że Bóg zaprasza mnie do podjęcia animatorskiej służby dla innych ludzi.   </li></ul>
              <p>Celem DSA jest przygotowanie zgłoszonych osób do bardziej świadomego przeżywania swojej relacji z Jezusem Chrystusem, a poprzez to do właściwego rozeznawania swej misji w Kościele i kształtowania postawy aktywnego podjęcia tej misji w trosce o siebie oraz innych. </p>
              <p>DSA przewidziana jest jako twór dynamiczny, a więc oprócz przekazu podstawowych treści teoretycznych, będzie stymulowana zajęciami warsztatowymi, których przebieg w dużej mierze uzależniony będzie od poziomu przygotowania i zaangażowania uczestników szkoły. W planie zajęć przewidziane są również pewne zadania do indywidualnego lub wspólnotowego wykonania pomiędzy kolejnymi zjazdami. </p>
              <p>Szkoła nie kończy się żadnym formalnym dyplomem czy certyfikatem. Lepsza jakość posługi i większa satysfakcja z bycia animatorem niech będzie wystarczającą motywację dla podjęcia trudu formacyjnego, bo przecież <em>„słudzy nieużyteczni jesteśmy; wykonaliśmy to, co powinniśmy wykonać”</em><em>  (Łk 17, 10).</em></p>
              <p><strong style={miod}>Założenia organizacyjne</strong></p>
              <p>Diecezjalna Szkoła Animatora zaprojektowana jest na okres 2 lat i składa się z 10 zjazdów, po 5 na każdy rok formacyjny. Pierwszy zjazd przewidziany jest dla wszystkich potencjalnych uczestników (<strong style={miod}>wszystkich obecnych animatorów!</strong>) i pozwoli wyłonić docelową grupę odbiorców DSA, którzy będą uczestniczyć w szkole do końca.  </p>
              <p>Spotkania będą się odbywać w dwóch ośrodkach diecezjalnych:</p>
              <p>1) <strong style={miod}>Duszpasterstwo Akademickie Resurrexit (DAR)</strong>, ul. Drzymały 1c, 45-342 Opole </p>
              <p>2) <strong style={miod}>Dom Miłosierdzia (DM)</strong>, ul. Rynek 38, 48-385 Otmuchów</p>
              <p>Przyjmuje się następujący ramowy plan kolejnych zjazdów:</p>
              <p><strong style={miod}>10:00 </strong><strong style={miod}>	</strong><strong style={miod}>Modlitwa do Ducha Świętego</strong></p>
              <p><strong style={miod}>10:30 </strong><strong style={miod}>	</strong><strong style={miod}>Wykład tematyczny</strong></p>
              <p><strong style={miod}>11:30</strong><strong style={miod}>	</strong><strong style={miod}>Adoracja Najświętszego Sakramentu</strong></p>
              <p><strong style={miod}>12:00  </strong><strong style={miod}>	</strong><strong style={miod}>Eucharystia z homilią</strong></p>
              <p><strong style={miod}>13:00 </strong><strong style={miod}>	</strong><strong style={miod}>Przerwa (kawa/herbata, kanapki/ciasto)</strong></p>
              <p><strong style={miod}>13:30 </strong><strong style={miod}>	</strong><strong style={miod}>Warsztat tematyczny</strong></p>
              <p><strong style={miod}>15:00</strong><strong style={miod}>	</strong><strong style={miod}>Koronka do Bożego Miłosierdzia</strong></p>
              <p><strong style={miod}>15:15</strong><strong style={miod}>	</strong><strong style={miod}>Panel dyskusyjny  (1 i 10 spotkanie)</strong> </p>
              <p>Zasadniczo udział w szkole nie wiąże się z żadną opłatą. Od uczestników <strong style={miod}>będzie jedynie zbierana kolekta podczas Mszy świętej</strong>, która przewidziana jest na pokrycie kosztów obsługi miejsca, na niezbędne materiały formacyjne oraz ewentualną ofiarę dla zaproszonych gości. Ponadto uczestnicy przywożą dla siebie kanapki i ewentualnie jakieś słodycze do kawy.  </p>
              <p><strong style={miod}>Tematyka spotkań</strong></p>
              <p>Na etapie projektowym DSA przyjęto następujący układ tematyczny, przy czym  organizatorzy zastrzegają sobie prawo do korekty tego układu w miarę zgłaszanych przez uczestników potrzeb (panel dyskusyjny!): </p>
              <ol>
                <li><strong style={miod}>Kim jest animator?</strong>
                  <ol>
                    <li><strong style={miod}>Wykład:</strong> Animator idealny – misja, sylwetka, wyzwania i rozwój.</li>
                    <li><strong style={miod}>Warsztat:</strong> Ankieta na start: „Ja w posłudze?” + samoocena  </li>
                    <li><strong style={miod}>Panel dyskusyjny:</strong> Czy szkoła animatora jest mi potrzebna? </li></ol></li>
                <li><strong style={miod}>Znam siebie.</strong>
                  <ol>
                    <li><strong style={miod}>Wykład:</strong> Moje wnętrze, osobowość, charakter, uczucia i dojrzałość. </li>
                    <li><strong style={miod}>Warsztat:</strong> Ja w relacjach osobowych – sztuka komunikacji. </li></ol></li>
                <li><strong style={miod}>Doświadczam Boga.</strong>
                  <ol>
                    <li><strong style={miod}>Wykład:</strong> Ja wobec kerygmatu. Prawa życia duchowego. </li>
                    <li><strong style={miod}>Warsztat:</strong> Głoszenie kerygmatu i dawanie świadectwa.</li></ol></li>
                <li><strong style={miod}>Słyszę Boga.</strong>
                  <ol>
                    <li><strong style={miod}>Wykład:</strong> Pismo Święte drogowskazem mojego życia.  </li>
                    <li><strong style={miod}>Warsztat:</strong> Przygotowanie i poprowadzenie grupy dzielenia.  </li></ol></li>
                <li><strong style={miod}>Rozmawiam z Bogiem.</strong>
                  <ol>
                    <li><strong style={miod}>Wykład:</strong> Typy, formy, metody modlitwy osobistej i wspólnotowej. </li>
                    <li><strong style={miod}>Warsztat:</strong> Modlitwa uwielbienia. Modlitwa wstawiennicza. </li></ol></li>
                <li><strong style={miod}>Trwam w Bogu.</strong>
                  <ol>
                    <li><strong style={miod}>Wykład:</strong> Żyję sakramentami i nauczaniem Kościoła.  </li>
                    <li><strong style={miod}>Warsztat:</strong> Zaangażowanie w liturgię – czytanie, psalm, modlitwa wiernych… </li></ol></li>
                <li><strong style={miod}>Współpracuję z Bogiem.</strong>
                  <ol>
                    <li><strong style={miod}>Wykład:</strong> Charyzmaty i posługi. Sztuka rozeznawania, przyjęcia i rozwoju darów duchowych. </li>
                    <li><strong style={miod}>Warsztat:</strong> Mój charyzmat? – próba rozeznania Bożej woli.  </li></ol></li>
                <li><strong style={miod}>Jestem odpowiedzialny przed Bogiem.</strong>
                  <ol>
                    <li><strong style={miod}>Wykład:</strong> Walka o siebie siłą wspólnoty – problem walki duchowej.</li>
                    <li><strong style={miod}>Warsztat:</strong> Widzieć, rozumieć i działać – współpraca z liderem. </li></ol></li>
                <li><strong style={miod}>Otwieram na Boga.</strong>
                  <ol>
                    <li><strong style={miod}>Wykład:</strong> Istota Ewangelizacji w dzisiejszym świecie.</li>
                    <li><strong style={miod}>Warsztat:</strong> Ewangelizacja bezpośrednia – okazja, która jest szansą.  </li></ol></li>
                <li><strong style={miod}>Jestem posłany przez Boga.</strong>
                  <ol>
                    <li><strong style={miod}>Wykład:</strong> Czynić uczniów – misja ewangelizatora. </li>
                    <li><strong style={miod}>Warsztat:</strong> Ankieta na koniec: „Ja w posłudze?” + samoocena</li>
                    <li><strong style={miod}>Panel dyskusyjny:</strong> Szkoła animatora – plusy i minusy.  </li></ol></li></ol>
              <p><strong style={miod}>Terminarz spotkań na rok 2019/2020</strong></p>
              <p>W ramach pierwszego roku formacyjnego DSA przewidziano następujące terminy i miejsca zjazdów: </p>

              <ol>
                <li><strong style={miod}>26.10.2019</strong> – Spotkanie 1: DAR Opole <span style={{color: 'red'}}>(Tu się zapisujemy!!!)</span></li>
                <li><strong style={miod}>23.11.2019</strong> – Spotkanie 2: DM Otmuchów</li>
                <li><strong style={miod}>25.01.2020</strong> – Spotkanie 3: DAR Opole</li>
                <li><strong style={miod}>29.02.2020</strong> – Spotkanie 4: DM Otmuchów</li>
                <li><strong style={miod}>28.03.2020</strong> – Spotkanie 5: DAR Opole</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
